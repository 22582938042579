<template>
    <div class="video-list-container">
        <div class="counter">
            <div>全部作品</div>
            <div class="total">{{ videosCount }} 个作品</div>
        </div>
        <div class="video-list">
            <div class="list-item" v-for="item in videos" :key="item.id">
                <div class="video">
                    <img :src="item.cover" alt="">
                </div>
                <div class="video-info">
                    <div class="video-title">{{ item.title }}</div>
                    <div class="video-views">
                        <div class="view-item">
                            <i class="like"></i>
                            <span>{{ item.likes }}</span>
                        </div>
                        <div class="view-item">
                            <i class="comment"></i>
                            <span>{{ item.playtimes }}</span>
                        </div>
                    </div>
                    <div class="video-option">
                        <div class="create-time">{{ reformatTime(item.created_at) }}</div>
                        <button class="btn-delete">
                            <i></i>
                            <span @click="deleteVideo(item.id)">删除作品</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="empty-text" v-if="videos.length === 0">没有更多的视频</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "VideoList",
        data() {
            return {
                videosCount: 0,
                videos: []
            }
        },
        methods: {
            deleteVideo(videoId) {
                if (window.confirm('您确定要删除这条视频数据吗？')) {
                    this.$http.delete('/api/ugsv/video/' + videoId).then(() => {
                        this.getVideos()
                    })
                }
            },
            getVideos() {
                this.$http.get('/api/web/video_list').then(res => {
                    this.videosCount = res.data.count
                    this.videos = res.data.videos
                })
            },
            reformatTime(time) {
                let date = new Date(time)
                return date.getFullYear() + '年'
                    + date.getMonth() + '月'
                    + date.getDate() + '日 '
                    + date.getHours().toString().padStart(2, '0') + ':'
                    + date.getMinutes().toString().padStart(2, '0')
            }
        },
        mounted() {
            this.getVideos()
        },
        activated() {
            this.getVideos()
        }
    }
</script>

<style scoped lang="less">
    .video-list-container {
        padding-top:30px;
        .counter {
            display:flex;
            align-items:center;
            padding-bottom:11px;
            margin:0 40px 12px 40px;
            border-bottom:1px solid #F0F0F0;
            color:#555555;
            font-weight:500;
            line-height:22px;
            .total {
                font-size:14px;
                font-weight:400;
                color:#ADADB1;
                padding:0 16px;
            }
        }
        .video-list {
            .list-item {
                display:flex;
                gap:0 20px;
                padding:12px 40px;
                .video {
                    flex-shrink:0;
                    width:100px;
                    height:136px;
                    border-radius:8px;
                    overflow:hidden;
                    background-color:#CCCCCC;
                    img {width:100%;height:100%;}
                }
                .video-info {
                    display:flex;
                    flex-grow:1;
                    flex-direction:column;
                    gap:16px 0;
                    font-size:14px;
                    > div {width:100%;}
                    .video-views {
                        display:flex;gap:0 16px;
                        color:#ADADB1;
                        .view-item {
                            display:flex;align-items:center;
                            i {display:inline-block;width:20px;height:20px;background-size:100% 100%;}
                            span {padding:0 4px;}
                            .like {background-image:url("../../assets/images/icons/ic_video_like.png")}
                            .comment {background-image:url("../../assets/images/icons/ic_video_com.png")}
                        }
                    }
                    .video-option {
                        height:44px;
                        display:flex;
                        justify-content:space-between;
                        align-items:flex-start;
                        .create-time { color:#ADADB1;}
                        .btn-delete {
                            display:none;
                            gap:0 6px;
                            align-items:center;
                            border:none;
                            border-radius:8px;
                            background-color:#F0F0F0;
                            color:#3F3F3F;
                            padding:10px 17px;
                            i {display:block;width:24px;height:24px;background-size:100% 100%;background-image:url("../../assets/images/icons/ic_video_del.png")}
                        }
                    }
                }
                &:hover {
                    background-color:#F5F5F5;
                    .video-option .btn-delete {display:flex;}
                }
            }
            .empty-text {
                font-size:14px;
                color:#ADADB1;
                font-weight:400;
                text-align:center;
                padding:48px 40px;
            }
        }
    }
</style>